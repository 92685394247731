import { IconClock, IconFlag } from '@troon/icons';
import type { JSX } from 'solid-js';

type Props = {
	selected: ViewStyle;
	onSelect: (style: ViewStyle) => void;
};

export function ViewBar(props: Props) {
	return (
		<div role="group" class="mb-8 flex flex-row gap-2 border-b border-neutral">
			<Button aria-pressed={props.selected === 'course'} onClick={() => props.onSelect('course')}>
				<IconFlag /> Course
			</Button>
			<Button aria-pressed={props.selected === 'time'} onClick={() => props.onSelect('time')}>
				<IconClock /> Tee time
			</Button>
			{/* <Button aria-pressed={props.selected === 'grid'} onClick={() => props.onSelect('grid')}>
				<IconGrid /> Grid
			</Button> */}
		</div>
	);
}

export type ViewStyle = 'course' | 'time' | 'grid';

function Button(props: JSX.ButtonHTMLAttributes<HTMLButtonElement>) {
	return (
		<button
			{...props}
			class="flex shrink grow-0 flex-row items-center gap-2 rounded-t-lg border-b-2 border-transparent px-4 py-2 text-lg font-semibold text-neutral-800 hover:border-brand-300 hover:bg-brand-100 aria-pressed:border-brand aria-pressed:text-neutral-900 lg:text-xl"
		>
			{props.children}
		</button>
	);
}
