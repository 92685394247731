import { IconGolfCart } from '@troon/icons';

export function NoFacilities() {
	return (
		<div class="flex w-full flex-col items-center gap-4 py-8">
			<IconGolfCart class="text-8xl text-brand" />
			<h2 class="text-center text-xl font-semibold">No courses found</h2>
			<p class="text-center text-lg text-neutral-800">
				No courses found. Remove or modify the search terms and try again.
			</p>
		</div>
	);
}

export function NoTeeTimes() {
	return (
		<div class="flex w-full flex-col items-center gap-4 py-8">
			<IconGolfCart class="text-8xl text-brand" />
			<h2 class="text-center text-xl font-semibold">No tee times found</h2>
			<p class="text-center text-lg text-neutral-800">
				No tee times were found. Remove or modify the search terms and try again.
			</p>
		</div>
	);
}
