import { IconMapPin } from '@troon/icons';
import { Button } from '@troon/ui';
import { For } from 'solid-js';
import { useUser } from '../../../providers/user';
import { getPopularLocations } from '../../../components/search-locations';
import type { ILocation } from '../../../components/search-locations';

type Props = {
	onSelectLocation: (info: ILocation) => void;
};

export function EmptyState(props: Props) {
	const user = useUser();

	return (
		<div class="flex w-full flex-col items-center gap-4 py-8">
			<IconMapPin class="text-8xl text-brand" />
			<h2 class="text-center text-xl font-semibold">Search by location</h2>
			<p class="text-center text-lg text-neutral-800">Enter a location to find courses.</p>
			<ul class="flex flex-row flex-wrap justify-center gap-4">
				<For each={getPopularLocations(user()?.me.card?.id)}>
					{(loc) => (
						<li>
							<Button
								appearance="secondary"
								class="normal-case"
								onClick={() => {
									props.onSelectLocation(loc);
								}}
							>
								{loc.displayValue}
							</Button>
						</li>
					)}
				</For>
			</ul>
		</div>
	);
}
